import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BulkUploadGopuffComponent } from './gopuff';



const routes: Routes = [
  { path: 'gopuff', loadChildren: () => import('./gopuff/gopuffUpload.module').then(m => m.GopuffBulkUploadNewModule), data: { title: 'Bulk Upload - WARP Admin' } },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BulkUploadRoutingModule { }
